<template>
  <div class="container bg-white" id="harga-paket">
    <div class="row">
      <div class="col-xs-18">
        <h3>{{ $t('tos.hargaPaket.title') }}</h3>
        <ol>
          <li>{{ $t('tos.hargaPaket.line1') }}</li>
          <li>{{ $t('tos.hargaPaket.line2') }}</li>
          <li>{{ $t('tos.hargaPaket.line3') }}</li>
          <li>{{ $t('tos.hargaPaket.line4') }}</li>
          <li>{{ $t('tos.hargaPaket.line5') }}</li>
          <li>{{ $t('tos.hargaPaket.line6') }}</li>
          <li>{{ $t('tos.hargaPaket.line7') }}</li>
        </ol>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'definisi',
};
</script>
