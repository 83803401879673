<template>
  <div class="container bg-white" id="komisi">
    <div class="row">
      <div class="col-xs-18">
        <h3>{{ $t('tos.komisi.title') }}</h3>
        <ol>
          <li>{{ $t('tos.komisi.line1') }}</li>
          <li>{{ $t('tos.komisi.line2') }}</li>
          <!--          <li>-->
          <!--            <div v-html="$t('tos.komisi.line2')"></div>-->
          <!--            <ol type="i">-->
          <!--              <li v-html="$t('tos.komisi.line3')"></li>-->
          <!--              <li v-html="$t('tos.komisi.line4')"></li>-->
          <!--            </ol>-->
          <!--          </li>-->
        </ol>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'komisi',
};
</script>
