import { render, staticRenderFns } from "./bookmark.vue?vue&type=template&id=2a408d01"
import script from "./bookmark.vue?vue&type=script&lang=js"
export * from "./bookmark.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports