<template>
  <div class="container bg-white" id="akun">
    <div class="row">
      <div class="col-xs-18">
        <h3>{{ $t('tos.akun.title') }}</h3>
        <ol>
          <li>{{ $t('tos.akun.line1') }}</li>
          <li>{{ $t('tos.akun.line2') }}</li>
          <li>{{ $t('tos.akun.line3') }}</li>
          <li>{{ $t('tos.akun.line4') }}</li>
          <li>{{ $t('tos.akun.line5') }}</li>
          <li>{{ $t('tos.akun.line6') }}</li>
          <li>{{ $t('tos.akun.line7') }}</li>
          <li>{{ $t('tos.akun.line8') }}</li>
          <!--          <li>-->
          <!--            <div v-html="$t('FAQ.deleteAccount.q1')"></div>-->
          <!--            <ol type="i">-->
          <!--              <li v-html="$t('FAQ.deleteAccount.a1m1')"></li>-->
          <!--              <li v-html="$t('FAQ.deleteAccount.a1m2')"></li>-->
          <!--              <li v-html="$t('FAQ.deleteAccount.a1m3')"></li>-->
          <!--            </ol>-->
          <!--            <div v-html="$t('FAQ.deleteAccount.a2')"></div>-->
          <!--            <div v-html="$t('FAQ.deleteAccount.a3')"></div>-->
          <!--            <ol>-->
          <!--              <li><div v-html="$t('FAQ.deleteAccount.s1')"></div></li>-->
          <!--              <li><div v-html="$t('FAQ.deleteAccount.s2')"></div></li>-->
          <!--              <li><div v-html="$t('FAQ.deleteAccount.s3')"></div></li>-->
          <!--              <li><div v-html="$t('FAQ.deleteAccount.s4')"></div></li>-->
          <!--              <li><div v-html="$t('FAQ.deleteAccount.s5')"></div></li>-->
          <!--              <li><div v-html="$t('FAQ.deleteAccount.s6')"></div></li>-->
          <!--              <li><div v-html="$t('FAQ.deleteAccount.s7')"></div></li>-->
          <!--            </ol>-->
          <!--          </li>-->
        </ol>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'akun',
};
</script>
