<template>
  <div class="container bg-white" id="definisi">
    <div class="row">
      <div class="col-xs-18">
        <h3>{{ $t('tos.definisi.title') }}</h3>
        <ol>
          <li>{{ $t('tos.definisi.line1') }}</li>
          <li>{{ $t('tos.definisi.line2') }}</li>
          <li>{{ $t('tos.definisi.line3') }}</li>
          <li>{{ $t('tos.definisi.line4') }}</li>
          <li>{{ $t('tos.definisi.line5') }}</li>
          <li>{{ $t('tos.definisi.line6') }}</li>
          <li>{{ $t('tos.definisi.line7') }}</li>
          <li>{{ $t('tos.definisi.line8') }}</li>
          <li>{{ $t('tos.definisi.line9') }}</li>
        </ol>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'definisi',
};
</script>
