<template>
  <div class="container bg-white" id="listing-properti">
    <div class="row">
      <div class="col-xs-18">
        <h3>{{ $t('tos.listingProperti.title') }}</h3>
        <ol>
          <li>{{ $t('tos.listingProperti.line1') }}</li>
          <li>
            <div v-html="$t('tos.listingProperti.line2')"></div>
            <ol type="i">
              <li v-html="$t('tos.listingProperti.line3')"></li>
              <li v-html="$t('tos.listingProperti.line4')"></li>
              <li v-html="$t('tos.listingProperti.line5')"></li>
              <li v-html="$t('tos.listingProperti.line6')"></li>
              <li v-html="$t('tos.listingProperti.line7')"></li>
              <li v-html="$t('tos.listingProperti.line8')"></li>
              <li v-html="$t('tos.listingProperti.line9')"></li>
              <li v-html="$t('tos.listingProperti.line10')"></li>
              <li v-html="$t('tos.listingProperti.line11')"></li>
            </ol>
          </li>
        </ol>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'definisi',
};
</script>
