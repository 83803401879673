<template>
  <div class="container bg-white" id="penolakan">
    <div class="row">
      <div class="col-xs-18">
        <h3>{{ $t('tos.penolakan.title') }}</h3>
        <ol>
          <li>{{ $t('tos.penolakan.line1') }}</li>
          <li>{{ $t('tos.penolakan.line2') }}</li>
          <li>{{ $t('tos.penolakan.line3') }}</li>
          <li>{{ $t('tos.penolakan.line4') }}</li>
          <li>{{ $t('tos.penolakan.line5') }}</li>
          <li>{{ $t('tos.penolakan.line6') }}</li>
          <li>{{ $t('tos.penolakan.line7') }}</li>
          <li>{{ $t('tos.penolakan.line8') }}</li>
          <li>{{ $t('tos.penolakan.line9') }}</li>
          <li>{{ $t('tos.penolakan.line10') }}</li>
          <li>{{ $t('tos.penolakan.line11') }}</li>
          <li>{{ $t('tos.penolakan.line12') }}</li>
          <li>{{ $t('tos.penolakan.line13') }}</li>
          <li>{{ $t('tos.penolakan.line14') }}</li>
          <li>{{ $t('tos.penolakan.line15') }}</li>
          <li>{{ $t('tos.penolakan.line16') }}</li>
          <li>{{ $t('tos.penolakan.line17') }}</li>
        </ol>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'definisi',
};
</script>
